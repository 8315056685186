module.exports = [
  {
    path: '/',
    name: 'home',
    prerender: true
  },
  {
    path: '/events',
    name: 'events',
    prerender: true
  },
  {
    path: '/install',
    name: 'install',
    prerender: true
  }
];
